import { Outlet } from "react-router-dom";
import Header1 from "./Header1";
import Footer from "./Footer";

const HomeLayout = () => {
  return (
    <>
      <Header1 />
      <Outlet />
      <Footer />
    </>
  );
};

export default HomeLayout;
