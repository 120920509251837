import { useState}from "react";
import {
  apiBaseUrl,
  apiUrl,
  isValidEmail,
} from "../important/commanFunction";
import axios from "axios";
import {  toast } from "react-toastify";

const RegisterPageStep1 = (props) => {
  const { stepChange, formValue, inputChange } = props;
  const [formInputValue, setFormInputValue] = useState({ accept: true });
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  
  const valueChange = (e) => {
    if (!loading) {
      if (e.target.name === "phoneNumber" || e.target.name === "otp") {

        if (e.target.name === "phoneNumber") {
          let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
          if (sanitizedValue.startsWith('+')) {
            sanitizedValue = "+" + sanitizedValue.replaceAll('+', '');
          } else {
            sanitizedValue = sanitizedValue.replaceAll('+', '');
          }
          setFormInputValue({
            ...formInputValue,
            [e.target.name]: sanitizedValue,
          });
          inputChange(e.target.name, sanitizedValue);
        } else {
          const numericValue = e.target.value.replace(/[^0-9]/g, "");
          setFormInputValue({
            ...formInputValue,
            [e.target.name]: numericValue,
          });
          inputChange(e.target.name, numericValue);
        }
      } else {
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: e.target.value,
        });
        inputChange(e.target.name, e.target.value);
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.userFullName) {
      errors.userFullName = "Name is required";
    } else if (data?.userFullName.length > 20) {
      errors.userFullName = "Name canNot be Greater than 20 char";
    }
    if (!data.userEmailAddress) {
      errors.userEmailAddress = "Email is required";
    } else if (!isValidEmail(data?.userEmailAddress)) {
      errors.userEmailAddress = "Please enter valid email address";
    }

    if (!data.accept) {
      errors.accept = "Accept the Terms of Service";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else {
      var expr = /^(0|91)?[6-9][0-9]{9}$/;
      if (!expr.test(data.phoneNumber.replace("+", ''))) {
        errors.phoneNumber = "Please enter valid phone number";
      }
    }

    return errors;
  };
 
  const otpSend = () => {
    const validationErrors = validateForm(formInputValue);
    if (Object.keys(validationErrors).length === 0) {
      const headers = {
        accept: "*/*",
        "Content-Type": "application/json",
      };
      setLoading(true)
      axios
        .post(
          apiBaseUrl + apiUrl.sendOTPToWebUser,
          {
            phoneNumber: formInputValue?.phoneNumber?.slice(-10),
            countryCode: 91
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.responseType === "SUCCESS") {
            stepChange(2);
            toast.success("OTP sent successfully");
          } else {
            toast.error(
              "Something went wrong please try again after sometime "
            );
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error:", error);
          toast.error("Something went wrong please try again after sometime ");
        }).finally(() => {
          setLoading(false)
        });
    } else {
      for (const [key, value] of Object.entries(validationErrors)) {
        toast.error(value);
      }
    }
  };

  return (
    <>
      <div className={` ${loading && 'disabled'}`} >
        {step === 1 && (
          <form className="business-stepper-form row g-3">
            <div className="col-12">
              <input type="text" className="form-control" id="name"
                name="userFullName"
                placeholder="Name"
                value={formInputValue?.userFullName || ""}
                onChange={valueChange}
                autoComplete="off" />
            </div>
            <div className="col-12">
              <input type="text" className="form-control" id="name"
                name="userEmailAddress"
                placeholder="Email"
                value={formInputValue?.userEmailAddress || ""}
                onChange={valueChange}
                autoComplete="off" />
            </div>
            <div className="col-12">
              <div className="form-control flag-input">
                <img src="/images/images/ind-icon.png" alt="India Flag" />
                <input type="number" id="phone" placeholder="Enter your mobile number"
                  autoComplete="off"
                  name="phoneNumber"
                  value={formInputValue?.phoneNumber || ""}
                  onChange={valueChange} />
              </div>
            </div>
            <div className="col-12 mt-5 mb-5">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="gridCheck" checked={formInputValue?.accept} onChange={(e) => {
                  setFormInputValue({
                    ...formInputValue,
                    accept: e.target.checked
                  })
                }} />
                <label className="form-check-label" for="gridCheck">
                  I have read the<a href="https://listy.city/privacy-policy" target="_blank">privacy policy</a> and
                  agree to <a href="https://listy.city/terms-of-use" target="_blank"> terms and conditions</a>.
                </label>
              </div>
            </div>
            <div className="col-12" onClick={otpSend}>
              <a href="javaScript:void(0)" className="btn btn-purple w-100">Send OTP</a>
            </div>
          </form>
        )}
       
      </div>
    </>
  );
};
export default RegisterPageStep1;
