import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { apiBaseUrl, apiUrl, pageRoutes } from "../important/commanFunction";
import moment from "moment";

const DetailsPage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const slug = searchParams.get("slug");
  const type = searchParams.get("type");

  const getDetails = () => {
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        apiBaseUrl + apiUrl.getDetailsByType,
        { slug, type },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.responseType === "SUCCESS") {
          setDetails(response?.data?.cardDetailsVO);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (type && slug) {
      getDetails();
    }
  }, [slug, type]);

  return (
    <>


      {loading && (
        <div className="loading_main_div">
          <div className="loader_base"></div>
        </div>
      )}
      {!loading && !details?.cardDetailId && (
        <div className="data_not_found">
          <p>Record not found</p>
        </div>
      )}
      {!loading && details?.cardDetailId && (
        <section className="unlock-city event-details pb-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="event-cover">
                  <img src={details?.images[0]} alt="Event Cover Image" />
                  <div className="col-12 col-md-6 event-cover-content">
                    {/* <a href="../event-listing.html" className="back-btn mb-5">
                                <img src="../images/back-arrow.svg" alt="Back Arrow">
                                Back
                            </a> */}

                    <h1>{details?.title}</h1>
                    {details?.category && <> <span className="badge pill bg-warning text-dark"><img src="/images/images/cat-icon.svg" alt="" /> {details?.category}</span><br /></>}

                    {details?.highlights[0] && <span className="badge pill bg-warning">{details?.highlights[0]}</span>}
                    {details?.address && <><p className="my-4">{details?.address}</p>
                      {(details?.latitude == 0 && details?.longitude == 0) ? (
                        <a href={`https://maps.google.com/?q=${details?.address}`} className="location" target="_blank"><img src="/images/images/yellow-map-location.svg" alt="Location Icon" />
                          View On Map</a>
                      ) : (
                        <a href={`https://maps.google.com/?q=${details?.latitude},${details?.longitude}`} className="location" target="_blank">
                          <img src="/images/images/yellow-map-location.svg" alt="Location Icon" />
                          View On Map
                        </a>
                      )}
                    </>}
                      
                        <a href={details?.images[0]} className="location mt-2" target="_blank"><img src="/images/images/eye-4-32.png" alt="image" />
                          View Image</a>
                      
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 pe-md-5 py-5">
                <div className="d-flex flex-column gap-5">
                  {details?.startDate &&
                    <div className="d-flex flex-column gap-2">
                      <h3>Duration</h3>
                      <div className="date-time">
                        <img src="/images/images/gray-calendar.svg" alt="Calendar Icon" />
                        <div className="date-time-inner">

                          <span className="date">
                            {details?.startDate && moment(details?.startDate)
                              .format('dddd, MMMM DD, YYYY')}{" "}
                            To{" "}
                            {details?.endDate
                              && moment(details?.endDate)
                                .format('dddd, MMMM DD, YYYY')}{" "}
                          </span>

                          <span className="time">
                            {details?.startDate && moment(details?.startDate)
                              .format("h:mm:ss a")}{" "}
                            -{" "}
                            {details?.endDate
                              && moment(details?.endDate)
                                .format("h:mm:ss a")}{" "}
                          </span>

                          {/* <span className="date">Tuesday, March 17, 2024</span>
                        <span className="time">7:30 PM - 8:30 PM </span> */}
                        </div>
                      </div>
                    </div>}
                  {details?.address &&
                    <div className="d-flex flex-column gap-2">
                      <h3>Event Location</h3>
                      <div className="date-time">
                        <img src="/images/images/gray-loc.svg" alt="Location Icon" />
                        <div className="date-time-inner" >
                          <span className="date"> {details?.address}</span>
                          <span className="time">
                            {(details?.latitude == 0 && details?.longitude == 0) ? (`https://maps.google.com/?q=${details?.address}`) : (`https://maps.google.com/?q=${details?.latitude},${details?.longitude}`)}
                           </span>
                        </div>
                        {(details?.latitude == 0 && details?.longitude == 0) ? (
                        <a href={`https://maps.google.com/?q=${details?.address}`} className="m-auto me-0" target="_blank">
                          <img
                          src="/images/images/link-icon.svg" alt="Link Icon" />
                          </a>
                          ) : (
                          <a href={`https://maps.google.com/?q=${details?.latitude},${details?.longitude}`} className="m-auto me-0" target="_blank">
                            <img
                            src="/images/images/link-icon.svg" alt="Link Icon" />
                            </a>
                            )}

                      </div>
                    </div>}
                  {details?.eventLink &&
                    <div className="d-flex flex-column gap-2">
                      <h3>View Event</h3>
                      <div className="date-time">
                        <div className="date-time-inner" >
                          <span className="time"> {details?.eventLink}</span>
                        </div>
                        <a href={details?.eventLink} className="m-auto me-0" target="_blank"><img
                          src="/images/images/link-icon.svg" alt="Link Icon" /></a>
                      </div>
                    </div>}
                </div>
              </div>

              <div className="col-12 col-md-6 pe-md-5 py-5">
                {details?.description && <h3>Description</h3>}
                {/* <p className="pt-4 pb-3">Unleash your creativity and craft your unique clay masterpieces in a calm and
                  inspiring environment. Whether you're a beginner or a seasoned artist, you'll enjoy a relaxing
                  and hands-on experience guided by our expert instructors. </p> */}
                <p>{details?.description}</p>
                <div className="d-flex flex-column gap-2 price">
                  {(details?.price || details?.price === 0) &&
                    <div className="date-time">
                      <h4>Price</h4>
                      <h3>{details?.price ? `₹ ${details?.price}**` : details?.price === 0 ? `Free` : ''}</h3>
                    </div>}
                  {type === "business" ? (
                    <Link className="nav-link btn btn-purple" to={pageRoutes.addEvent}>Launch Event</Link>
                  ) : (
                    <Link className="nav-link btn btn-purple" to={`${pageRoutes.enrollNowPage}/${details?.cardDetailId}`}>I Am Interested</Link>
                  )}
                  {details?.price && <p>** subject to host or service provider</p>}
                </div>
                <div className="social-media mt-5">
                  <h3>Connect</h3>
                  <ul className="mt-3">

                    {details?.instagramLink &&
                      <li>
                        <a href={details?.instagramLink} target="_blank"><img
                          src="/images/images/instagram.png" alt="Instagram Icon" /></a>
                      </li>}
                    {details?.facebookLink &&
                      <li>
                        <a href={details?.facebookLink} target="_blank"><img
                          src="/images/images/facebook.png" alt="Facebook Icon" /></a>
                      </li>}

                    {details?.contactNumber &&
                      <li>
                        <a href={`tel:${details?.contactNumber}`}><img src="/images/images/phone-icon.svg" alt="Phone Icon" /></a>
                      </li>}
                    {details?.website &&
                      <li>
                        <a href={details?.website} target="_blank"><img src="/images/images/website.svg" alt="Website Icon" /></a>
                      </li>
                    }
                    {details?.email &&
                      <li >
                        <a href={`mailto:${details?.email}`}>
                          <img width={"33px"} src="/images/images/email.png" alt="Website Icon" />

                        </a>
                      </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DetailsPage;
