import axios from "axios";
import { useEffect,  useState } from "react";
import {
  apiBaseUrl,
  apiUrl,
  isValidUrl,
  isImageType,
  imagetypeError,
  trimString,
} from "../important/commanFunction";
import {  toast } from "react-toastify";
import cityName from "../important/city.json";
import categoryList from "../important/category.json";

import Select from 'react-select';

const RegisterPageStep2 = (props) => {
  const { stepChange, formValue } = props;
  const [loading, setLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);

  const [formInputValue, setFormInputValue] = useState({
    installationKey: formValue?.webInstallationKey,
    emailAddress: formValue?.emailAddress,
    registeredPhoneNumber: formValue?.phoneNumber,
    businessContactNumber: formValue?.phoneNumber,
    countryCode: 91,
    userBusinessLocation:'ANYWHERE'
  });

  const [googleAddress, setGoogleAddress] = useState({})

  const valueChange = (e) => {
    if (!loading) {
      if (e.target.name === "businessContactNumber") {
        const numericValue = e.target.value.replace(/[^0-9]/g, "");
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: numericValue,
        });
      } else {
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  useEffect(() => {
    setFormInputValue({
      ...formInputValue,
      selectedTags: [],
    });
  }, [formInputValue?.categoryId]);
  const validateForm = (data) => {

    const errors = {};

    if (!data.cityId) {
      errors.cityId = "City is required";
    }

    if (!data.businessName) {
      errors.businessName = "Business Name is required";
    } else if (data?.businessName.length > 50) {
      errors.businessName = "Business Name canNot be Greater than 50 char";
    }

    if (data.selectedTags.length === 0) {
      errors.selectedTags = "Sub Category is required";
    }

    if (!data.categoryId) {
      errors.categoryId = "Category is required";
    }

    if (!googleAddress.businessAddress) {
      errors.businessAddress = "Business Address  is required";
    }
    if (!data.userBusinessLocation) {
      errors.userBusinessLocation = "Business Location  is required";
    }

    if (!data?.facebookLink) {
      // errors.facebookLink = "Event Link  is required";
    } else if (!isValidUrl(data?.facebookLink)) {
      errors.facebookLink = "Please enter valid  url";
    } else if (data?.facebookLink?.length > 100) {
      errors.facebookLink = "Url can not be greater than 100 char";
    }

    if (!data?.instagramLink) {
      // errors.instagramLink = "Event Link  is required";
    } else if (!isValidUrl(data?.instagramLink)) {
      errors.instagramLink = "Please enter valid  url";
    } else if (data?.instagramLink?.length > 100) {
      errors.instagramLink = "Url can not be greater than 100 char";
    }

    if (!data?.websiteUrl) {
      // errors.websiteUrl = "Event Link  is required";
    } else if (!isValidUrl(data?.websiteUrl)) {
      errors.websiteUrl = "Please enter valid  url";
    } else if (data?.websiteUrl?.length > 100) {
      errors.websiteUrl = "Url can not be greater than 100 char";
    }

    if (!data.productImage) {
      // errors.productImage = "Image  is required";
    } else if (!isImageType(data?.productImage?.name)) {
      errors.productImage = imagetypeError;
    }

    return errors;
  };

  const userRegister = async (img) => {
    const headers = {
      accept: "*/*",
      "Content-Type": "application/json",
    };
    axios
      .post(
        apiBaseUrl + apiUrl.registerUserBusiness,
        {
          businessAddress: googleAddress?.businessAddress,
          latitude: googleAddress?.latitude,
          longitude: googleAddress.longitude,
          businessContactNumber: formValue?.phoneNumber?.slice(-10),
          businessDescription: formInputValue?.description,
          businessName: formInputValue?.businessName,
          businessStreetAddress: formInputValue?.businessStreetAddress,
          businessTitle: formInputValue?.businessTitle,
          categoryId: formInputValue?.categoryId,
          cityId: formInputValue?.cityId,
          countryCode: 91,
          emailAddress: formInputValue?.emailAddress,
          imageUrl: img || '',
          installationKey: formInputValue?.installationKey,
          registeredPhoneNumber: formValue?.phoneNumber?.slice(-10),
          selectedTags: formInputValue.selectedTags,
          userBusinessLocation: formInputValue?.userBusinessLocation,
          userId: 0,
          facebook: formInputValue?.facebookLink,
          instagram: formInputValue?.instagramLink,
          website: formInputValue?.websiteUrl
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.responseType === "SUCCESS") {
          stepChange(3);

        } else {
          toast.error(
            "Something went wrong please try again after sometime "
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong please try again after sometime ");
      })
      .finally(() => {
        setLoading(false);
      });

  };
  const headers2 = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const fileUploadRequest = () => {
    axios
      .post(
        apiBaseUrl + apiUrl.getUploadFileUrl,
        {
          cityId: formInputValue?.cityId,
          latitude: googleAddress?.latitude ,
          longitude: googleAddress.longitude ,
          registeredPhoneNumber: formValue?.phoneNumber?.slice(-10),
          installationKey: formInputValue?.installationKey,
          userId: 0,
          countryCode: 91,
          cardType: "BUSINESS",
          fileName: formInputValue?.productImage?.name,
          expirationDate:new Date(),
        }, {
        headers: headers2,
      }
      )
      .then((response) => {
        if (response?.status === 200) {
          const contentType = response?.data?.mimeType
          const uploadUrl = response?.data?.url;
          const imageUrl = response?.data?.fileAccessUrl;

          let data = formInputValue?.productImage;

          let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: uploadUrl,
            headers: {
              'Content-Type': contentType
            },
            data: data
          };
          axios.request(config)
            .then((response) => {
              if (response.status === 200) {
                userRegister(imageUrl)
              } else {
                setLoading(false);
                toast.error("Something went wrong please try again after sometime");
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Something went wrong please try again after sometime");
            });
        }
        else {
          setLoading(false);
          toast.error(
            "Something went wrong please try again after sometime"
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong please try again after sometime");
      })
  }
  const submit = async () => {
    const validationErrors = await validateForm(formInputValue);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
     
      if (formInputValue?.productImage?.name) {
        fileUploadRequest()
      } else {
        userRegister('')
      }
      
    } else {
      for (const [key, value] of Object.entries(validationErrors)) {
        toast.error(value);
      }
    }
  };

  function getTagsByCategoryId(categoryId) {
    const selectedCategory = category.filter(
      (category) => category?.categoryId == categoryId
    );

    if (selectedCategory[0]) {
      return selectedCategory[0]?.tags;
    }

    return [];
  }
  const [selectedImage, setSelectedImage] = useState(null);

  const fileChange = (e) => {

    const file = e.target.files[0]; // Get the selected file

    if (file) {
      if (!file.type.startsWith('image/')) {
        toast.error(
          "Please upload an image file."
        );
      }

      if (file.size > 2 * 1024 * 1024) {
        toast.error(
          "File size exceeds 2MB. Please upload a smaller image."
        );
      }

      if (file.size <= 2 * 1024 * 1024 && file.type.startsWith('image/')) {
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: e.target.files[0],
        });
        const file = e.target.files[0];
        if (file) {
          const objectURL = URL.createObjectURL(file);
          setSelectedImage(objectURL);
        }
      }

    }

  };
  useEffect(() => {
    formInputValue?.categoryId &&
      setTagsList(getTagsByCategoryId(formInputValue?.categoryId));
  }, [formInputValue?.categoryId]);

  const [category, setCategory] = useState([]);
  useEffect(() => {
  
    setCategory(categoryList?.categoryList)
  }, [categoryList])

  useEffect(() => {
    const initAutocomplete = () => {
      const input = document.getElementById('autocomplete');
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        setGoogleAddress({
          ...googleAddress,
          businessAddress: place?.formatted_address,
          latitude: place?.geometry?.location?.lat(),
          longitude: place?.geometry?.location?.lng(),
        });
      });
    };

    initAutocomplete();
  }, []);

  const [suggestedCities, setSuggestedCities] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);

  const handleInputChange = (e) => {
    setFormInputValue({
      ...formInputValue,
      cityName: '',
      city: '',
    });
    const value = e.target.value;
    setInputValue(value);

    const filteredCities = cityName?.cityNameList.filter(city =>
      city.cityName.toLowerCase().includes(trimString(value).toLowerCase())
    );

    setSuggestedCities(filteredCities);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setInputValue(city.cityName);
    setFormInputValue({
      ...formInputValue,
      cityName: city.cityName,
      cityId: city.cityId,
    });
    setSuggestedCities([]);
  };

  const cityLoad = (value) => {
    const filteredCities = cityName?.cityNameList.filter(city =>
      city.cityName.toLowerCase().includes(trimString(value).toLowerCase())
    );
    setSuggestedCities(filteredCities);
  };

  const handleSwitchToggle = (e) => {
    const newValue = e.target.checked ? "CITY_AND_NEARBY" : "ANYWHERE";
    setFormInputValue({
      ...formInputValue,
      userBusinessLocation: newValue,
    });
  };

  return (
    <>
      <div className={`row ${loading && "disabled"}`}>
        <form className="business-stepper-form row g-3">
        <div className="col-12 d-flex align-items-center justify-content-between">
            <label className="form-check-label" for="toggleSwitch">Where do you provide your
              services</label>
            <div className="form-check form-switch switch2">
              <label for="onlineOfflineButton" className="text-offline">Anywhere</label>
              <input className="form-check-input" type="checkbox" id="onlineOfflineButton" name="userBusinessLocation"
                checked={formInputValue.userBusinessLocation === "CITY_AND_NEARBY"}
                onChange={handleSwitchToggle}
              />
              <label for="onlineOfflineButton" className="text-online">In my City</label>
            </div>
          </div>
          <div className="col-12">
            <input className="form-control" id="business_name"
              autoComplete="off"
              type="text"
              placeholder="Business Name"
              name="businessName"
              value={formInputValue?.businessName}
              onChange={valueChange} />
          </div>
         
          <div className="col-12">
            <div className="intro-search-field utf-chosen-cat-single">

              <input
                className="form-select form-control"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => cityLoad(inputValue)}
                onBlur={() => {
                  setTimeout(() => {
                    setSuggestedCities([])
                  }, 500)
                }
                }
                placeholder="Type to search city"
                style={{ margin: 0 }}
              />
              <div style={{
                width: "100%",
                position: "relative"
              }}>
                {suggestedCities?.length > 0 && <ul style={{
                  position: "absolute",
                  background: "#fff",
                  border: "solid 1px #ddd",
                  maxHeight: "150px",
                  overflow: "auto",
                  listStyle: "none",
                  padding: "10px",
                  zIndex: 99,
                  width: "100%"
                }}>
                  {suggestedCities?.map(city => (
                    <li style={{ padding: "0px", fontSize: "16px" }} key={city.cityId} onClick={() => handleCityClick(city)}>
                      {city.cityName}
                    </li>
                  ))}
                </ul>}
              </div>
            </div>
          </div>
          <div className="col-12">
            <input
              autoComplete="off"
              className="form-control"
              type="text" id="autocomplete" placeholder="Type to search location" />
          </div>
         
              <div className="col-12 col-md-6">
                <select
                  className="form-select form-control" aria-label="Default select example"
                  name="categoryId"
                  value={formInputValue?.categoryId}
                  onChange={valueChange}
                >
                  <option value="" disabled selected>
                    Select a Category
                  </option>
                  {category?.map((res, index) => (
                    <option key={index} value={res?.categoryId}>
                      {res?.categoryText}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 sub_category_div" >
                <Select
                value={tagsList
                  .filter((tag) => formInputValue.selectedTags.includes(tag.id))
                  .map((tag) => ({ value: tag.id, label: tag.name }))}
                  className=""
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    setFormInputValue({
                      ...formInputValue,
                      selectedTags: e?.map((res) => res.value)
                    });
                  }}
                  isMulti
                  options={tagsList?.map((res) => {
                    return { value: res.id, label: res.name }
                  })}
                />
              </div>
              <div className="col-12 col-md-12">
            <input
              className="form-control"
              type="url"
              name="facebookLink"
              value={formInputValue?.facebookLink}
              onChange={valueChange}
              placeholder="Facebook Url"
            />
          </div>
          <div className="col-12 col-md-12">
            <input
              className="form-control"
              type="url"
              name="instagramLink"
              value={formInputValue?.instagramLink}
              onChange={valueChange}
              placeholder="Instagram Url"
            />
          </div>
          <div className="col-12 col-md-12">
            <input
              className="form-control"
              type="url"
              name="websiteUrl"
              value={formInputValue?.websiteUrl}
              onChange={valueChange}
              placeholder="Website Url"
            />
          </div>
          <div className="col-12 col-md-12">
            <textarea
              rows="3"
              style={{minHeight:"100px"}}
              className="form-control"
              type="url"
              name="description"
              value={formInputValue?.description}
              onChange={valueChange}
              placeholder="Enter description "
            ></textarea>
          </div>
          <div className="col-12 position-relative">
            <div className="custom-file">
              <input type="file" id="file_upload" className="custom_file_uploader" accept="image/*"
                name="productImage"
                onChange={fileChange} />
              <div className="custom-file-content">
                <img src="/images/images/upload-icon.svg" alt="Upload Icon" />
                <strong>Upload File</strong>
                <span className="ms-auto">(JPEG/PNG Under 2mb)</span>
              </div>
            </div>

          </div>
          <div className="col-md-6 col-12" >
            {selectedImage && (
              <div style={{ position: 'relative', display: 'inline-block', marginTop: '10px' }}>
                <img
                  src={selectedImage}
                  alt="Preview"
                  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '8px' }}
                />
                <span
                  onClick={() => {
                    setFormInputValue({
                      ...formInputValue,
                      productImage: null,
                    });
                    setSelectedImage(null)
                  }}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  &times;
                </span>
              </div>
            )}
          </div>
          <div className="col-12">
            <a href="javaScript:void(0)" className="btn btn-purple w-100" onClick={() => {
              submit();
            }}>Continue</a>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterPageStep2;
