import { Link } from "react-router-dom";
import { appDownloadUrl, pageRoutes } from "../important/commanFunction";

const Header2 = () => {
  return (
    <>
      <header className="header px-2 px-md-5">
        <nav className="navbar navbar-expand-md navbar-dark bg-transparent" aria-label="Fourth navbar example">
          <div className="container-fluid">
            <Link className="navbar-brand" to={pageRoutes?.home}>
              <img src="/images/images/logo.png" alt="Brand Logo" />
            </Link>
            <ul className="navbar-nav nowrap align-items-center gap-4 ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link btn btn-purple" href={appDownloadUrl} target="_blank">Download App</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header2;
