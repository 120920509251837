const pageRoutes = {
  home: "/",
  registerBusiness: "/register-business",
  registerBusinessAdmin: "/register-admin-business",
  addEvent: "/add-event",
  addAdminEvent: "/add-admin-event",
  webAddEvent: "/web-add-event",
  webRegisterBusiness: "/web-register-business",
  aboutPage: "/about",
  privacyPolicy: "/privacy-policy",
  termsPage: "/terms-of-use",
  appPrivacyPolicy: "/app-privacy-policy",
  appTermsPage: "/app-terms-of-use",
  generalHelp: "/general-help",
  businessHelp: "/business-help",
  detailsPage: "/details",
  qrCodePage: "/qr-code",
  enrollNowPage: "/enroll-now",
};

const apiUrl = {
  sendOTPToWebUser: "/otp/web/sendOTPToWebUser",
  verifyWebUserRegistrationOTP: "/verify/web/verifyWebUserRegistrationOTP",
  verifyEventEnrollmentOTP: "/enroll/web/verifyEventEnrollmentOTP",
  getDetailsByType: "/general/web/getDetailsByType",
  getCategorySubCategory: "/static/getCategorySubCategory",
  registerUserBusiness: "/secure/web/webRegisterUserBusiness",
  webRegisterUserEvent: "/event/web/webRegisterUserEvent",
  getUploadFileUrl: "/image/web/getUploadFileUrlForWeb",
};

const imagetypeError =
  "Please upload file having extensions .jpeg/.jpg/.png/.gif only.";

const trimString = (val) => {
  const outputString = val.replace(/\s+/g, ' ').trim();
  return outputString
}

const apiBaseUrl = "https://transpose-gateway.listy.city";
const apiBaseUrl2 = "https://transpose-gateway.listy.city";
// const apiBaseUrl2 = "https://api.listy.city";

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const allowedImage = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

const listyCityMailto = "mailto: contact.listy@listy.city";
const qrCodeImageUrl = "/images/images/qr-code.png";
const qrCodeMobileImageUrl = "/images/images/qr-code-mobile.png";
const appDownloadUrl = "https://onelink.to/55h5ud";
const googleMapApiKey =   "AIzaSyCARflceA-gK6Py3fHCRtujVZCzcY9Uj24"
const youtubeIframeUrl =   "https://www.youtube.com/embed/cMBuo1Zg5KI"

const isValidEmail = (val) => {
  if (emailRegex.test(val)) {
    return true;
  } else {
    return false;
  }
};

const isValidUrl = (url) => {
  try {
    const parsedURL = new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
const isImageType = (name) => {
  if (allowedImage.exec(name)) {
    return true;
  } else {
    return false;
  }
};
const isValidIndiaPhone = (val) => {
  const regex = /^\d{10}$/;
  if (regex.test(val)) {
    return true;
  } else {
    return false;
  }
};


const dateSendFormate2 = (val) => {
  if (val) {
    var originalDate = new Date(val);

    var day = originalDate.getDate();
    var month = originalDate.getMonth() + 1;
    var year = originalDate.getFullYear();
    var hours = originalDate.getHours();
    var minutes = originalDate.getMinutes();
    var seconds = originalDate.getSeconds();

    month = (month < 10) ? '0' + month : month;
    day = (day < 10) ? '0' + day : day;
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    var formattedDateString = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDateString;
  } else {
    return ''
  }


}
export {
  dateSendFormate2,
  googleMapApiKey,
  qrCodeImageUrl,
  listyCityMailto,
  appDownloadUrl,
  qrCodeMobileImageUrl,
  youtubeIframeUrl,
  pageRoutes,
  trimString,
  isValidIndiaPhone,
  imagetypeError,
  apiUrl,
  apiBaseUrl,
  isValidEmail,
  isImageType,
  apiBaseUrl2,
  isValidUrl,
};
