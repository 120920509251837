import { useEffect, useState } from "react";
import RegisterPageStep1 from "./RegisterPageAdminStep1";
import RegisterPageStep2 from "./RegisterPageAdminStep2";
import RegisterPageStep3 from "./RegisterPageAdminStep3";
import {  toast } from "react-toastify";

const RegisterBusinessPage = () => {
  const [registerStep, setRegisterStep] = useState(1);
  const [formValue, setFormValue] = useState({});

  const inputChange = (name, value) => {
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (registerStep === 3) {
      toast.success("Business registered successfully");
    }
  }, [registerStep])

  return (
    <>
      <section className="business-onboarding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-none d-md-block">
              <img src="/images/images/grow-image.png" className="w-100 mb-5" alt="Business List Image" />
            </div>
            <div className="col-12 col-md-6">
              <h2 className="primary text-center mb-5">Grow your business for free in 3 easy, quick steps.</h2>
              <ul className="stepper-form">
                <li>
                  <div className="stepper-item">
                    <span>1</span> Register
                  </div>
                  <span className={`progress ${registerStep === 1 && 'bg-warning'}`}></span>
                </li>
                <li>
                  <div className="stepper-item">
                    <span>2</span> Details
                  </div>
                  <span className={`progress ${registerStep === 2 && 'bg-warning'}`}></span>
                </li>
                <li>
                  <div className="stepper-item">
                    <span>3</span> List
                  </div>
                  <span className={`progress ${registerStep > 2 && 'bg-warning'}`}></span>
                </li>
              </ul>
              {registerStep === 1 && (
                <RegisterPageStep1
                  formValue={formValue}
                  inputChange={inputChange}
                  stepChange={(val) => {
                    setRegisterStep(val);
                  }}
                />
              )}
              {registerStep === 2 && (
                <RegisterPageStep2
                  formValue={formValue}
                  inputChange={inputChange}
                  stepChange={(val) => {
                    setRegisterStep(val);
                  }}
                />
              )}
              {(registerStep === 3 || registerStep === 4) && (
                <RegisterPageStep3
                  registerStep={registerStep}
                />
              )}
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default RegisterBusinessPage;
