
const QrCodepage = () => {
  return (
    <div className="mb-5 mt-5">
    </div>

  );
};

export default QrCodepage;
