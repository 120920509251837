import { useEffect, useState } from "react";
import EnrollNowPageStep1 from "./EnrollNowPageStep1";
import EnrollNowPageStep2 from "./EnrollNowPageStep2";
import EnrollNowPageStep3 from "./EnrollNowPageStep3";
import {  toast } from "react-toastify";
import { Link , useLocation, useRoutes, useParams} from "react-router-dom";

const RegisterBusinessPage = () => {
  const [registerStep, setRegisterStep] = useState(1);
  const [formValue, setFormValue] = useState({});
  const router =  useLocation()
  // const router =  useRoutes()
  const { id } = useParams();

  const inputChange = (name, value) => {
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (registerStep === 3) {
      toast.success("Reminder added successfully");
    }
  }, [registerStep])

  return (
    <>
      <section className="business-onboarding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-none d-md-block">
              <img src="/images/images/grow-image.png" className="w-100 mb-5" alt="Business List Image" />
            </div>
            <div className="col-12 col-md-6">
              <h2 className="primary text-center mb-5">Get reminded in 3 easy quick steps.</h2>
              <ul className="stepper-form">
                <li>
                  <div className="stepper-item">
                    <span>1</span> Details
                  </div>
                  <span className={`progress ${registerStep === 1 && 'bg-warning'}`}></span>
                </li>
                <li>
                  <div className="stepper-item">
                    <span>2</span> OTP
                  </div>
                  <span className={`progress ${registerStep === 2 && 'bg-warning'}`}></span>
                </li>
                <li>
                  <div className="stepper-item">
                    <span>3</span> All Set!
                  </div>
                  <span className={`progress ${registerStep > 2 && 'bg-warning'}`}></span>
                </li>
              </ul>
              {registerStep === 1 && (
                <EnrollNowPageStep1
                  formValue={formValue}
                  inputChange={inputChange}
                  stepChange={(val) => {
                    setRegisterStep(val);
                  }}
                />
              )}
              {registerStep === 2 && (
                <EnrollNowPageStep2
                  formValue={formValue}
                  inputChange={inputChange}
                  stepChange={(val) => {
                    setRegisterStep(val);
                  }}
                />
              )}
              {(registerStep === 3 || registerStep === 4) && (
                <EnrollNowPageStep3
                  registerStep={registerStep}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterBusinessPage;
